<template>
  <div>
    <el-image :src="bg" class="imgRe"></el-image>
    <el-row class="cont">
      <div class="xz-b">
        <el-col :span="12">
          <Titletext :text="liveInfo.subject" :colorType="'live'" />
          <p class="color-w text-l">直播时间:{{ liveInfo.livestartDate }}</p>
          <Titletext text="课程简介" :colorType="'live'" />
          <p class="color-w text-l">{{ liveInfo.scheduleinfo }}</p>
          <Titletext text="课程老师" :colorType="'live'" />
          <p class="color-w text-l">{{ liveInfo.speakerinfo }}</p>
        </el-col>
        <el-col
          :span="11"
          :offset="1"
          class="xz-bgColor wxName text-l p-l-30 p-r-30 p-t-30"
        >
          <p class="f-s-35">您好，首次登录</p>
          <p class="f-s-20">请正确填写您的微信昵称,获取直播观看权限</p>
          <el-input
            ref="tPhone"
            placeholder="请输入微信昵称"
            class="input-with-select"
            v-model="liveName"
            clearable
          >
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
          <el-button type="success" class="m-t-32 w-100" @click="goLive"
            >进入直播间<i class="el-icon-right el-icon--right"></i
          ></el-button>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
import Titletext from "@/components/pay/titleText";
export default {
  components: {
    Titletext,
  },
  data() {
    return {
      liveName: "", // 直播间昵称
      bg: require("@/assets/img/live/liveBg.png"),
      liveInfo: {
        description: "", // 课堂介绍
        livestartDate: "", // 直播开始时间
        subject: "", // 直播昵称
        teachers: "", // 直播讲师
      },
      liveId: "", // 直播id
    };
  },
  created() {
    if (!this.$f.uid()) {
      this.$f.goLogon();
    }
  },
  methods: {
    // 获取直播信息
    getLiveInfo() {
      let query = this.$route.query;
      this.liveId = query.LiveId;
      let data = {
        LiveId: query.LiveId * 1,
        uid: !this.$f.uid() ? null : this.$f.uid(),
        belong: this.$f.getBelong(),
        deviceId: this.$f.getSession('ip')
      };
      this.Api.Live.getLiveInfo(data).then((res) => {
        if (res.data.studenturl == null) {
          this.liveInfo = res.data;
        } else {
          this.liveInfo = res.data;
          this.$router.push({
            path: "/lookLive",
            query: { lookDress: res.data.studenturl },
          });
        }
      });
    },
    // 进入直播间
    goLive() {
      if (this.liveName == "") {
        this.$message.error("请填写昵称");
        return false;
      }
      let LiveId = this.liveId * 1;
      let data = {
        LiveId,
        uid: this.$f.uid(),
        uname: this.liveName,
        belong: this.$f.getBelong(),
        deviceId: this.$f.getSession('ip')
      };
      this.Api.Live.getLiveUri(data).then((res) => {
        if (res.status == 200) {
          this.$router.push({
            path: "/lookLive",
            query: { lookDress: res.data.studenturl },
          });
        } else {
          this.$message.error("未知错误,请重试或者联系伴学老师");
        }
      }).catch(err => {
        alert(err)
      });
    },
  },
  computed: {
    loginType() {
      return this.$store.state.overall.loginType;
    },
  },
  watch: {
    loginType: {
      deep: true,
      immediate: true,
      handler() {
        this.getLiveInfo();
      },
    },
  },
};
</script>

<style scoped>
.cont {
  position: absolute;
  top: 20%;
  width: 100%;
}
.imgRe {
  position: relative;
  height: 100vh;
  width: 100bw;
}
.wxName {
  min-height: 500px;
}
</style>